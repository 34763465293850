import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const BACKENDURL = "https://dev.backend.drops.datacurve.io";

export const getModelBackPackList = createAsyncThunk(
  "cio/getModelBackPackList",
  async ({ companyId, page, limit }, thunkAPI) => {
    try {
      const region = "us-central1";
      let res = await axios.get(
        `${BACKENDURL}/api/cioDashboard/modelBackpackNFTDetailCIO/${region}/${companyId}/${page}/${limit}`
      );
      console.log("res :", res.data.data);
      const modelBackpackList = res.data.data;
      return modelBackpackList;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getModelBackPackDetails = createAsyncThunk(
  "cio/getModelBackPackDetails",
  async ({ modelBackpackId }, thunkAPI) => {
    try {
      let res = await axios.get(
        `${BACKENDURL}/api/cioDashboard/getBackpackNFTDetail/${modelBackpackId}`
      );
      console.log("res :", res.data.data);
      const modelBackpackDetails = res.data.data;
      const projectId = modelBackpackDetails.backpackNFTDetail.project_id;
      thunkAPI.dispatch(getModelBackpackModelsList(projectId));
      return modelBackpackDetails;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getModelBackpackModelsList = createAsyncThunk(
  "cio/getModelBackpackModelsList",
  async (projectId, thunkAPI) => {
    try {
      let res = await axios.get(
        `${BACKENDURL}/api/cioDashboard/getAllDeployedModelList/${projectId}`
      );
      console.log("res :", res.data.data);
      const modelBackpackModelsList = res.data.data;
      console.log("modelBackpackModelsList :",modelBackpackModelsList);
      const pipelinedModels = [];
      const uiModels = [];

      modelBackpackModelsList.forEach((item) => {
        if (item.model.trainingPipeline) {
          pipelinedModels.push(item);
        } else {
          uiModels.push(item);
        }
      });
      const modelLists = { pipelinedModels, uiModels };
      return modelLists;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// https://dev.backend.drops.thenftbrewery.com/api/cioDashboard/getNftMintedDeployedModel/664c83ccfe1359ef3b382356

export const getModelBackpackNFTDetails = createAsyncThunk(
  "cio/getModelBackpackNFTDetails",
  async ({ modelId }, thunkAPI) => {
    try {
      let res = await axios.get(
        `${BACKENDURL}/api/cioDashboard/getNftMintedDeployedModel/${modelId}`
      );
      console.log("res :", res.data.data);
      const modelBackpackNFTDetails=res.data.data;
      const requiredObj = {
        name:modelBackpackNFTDetails.jsonDetails[0].jsonDetails.name,
        description:modelBackpackNFTDetails.jsonDetails[0].jsonDetails.description,
        image:modelBackpackNFTDetails.jsonDetails[0].jsonDetails.image,
        attributes:modelBackpackNFTDetails.jsonDetails[0].jsonDetails.attributes,
        tokenId:modelBackpackNFTDetails.jsonDetails[0].tokenId
      };
      console.log("dnjkrfnkdnd :",requiredObj)
      console.log("modelBackpackNFTDetails here :",modelBackpackNFTDetails);
      return requiredObj;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getModelCuratorModels = createAsyncThunk(
  "cio/getModelCuratorModels",
  async ({ modelCuratorEmail }, thunkAPI) => {
    try {
      let res = await axios.get(
        `${BACKENDURL}/api/cioDashboard/deployedModelByPrincipleEmail/${modelCuratorEmail}`
      );
      const data = res.data.data[0];
      return data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);