import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  Tooltip,
  Filler,
} from "chart.js";
import { Pie, getElementsAtEvent } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import ModelCostBarGraphComponent from "../ModelCostBarGraphComponent/ModelCostBarGraphComponent";
import LineChartComponent from "../LineChartComponent/LineChartComponent";

ChartJS.register(ArcElement, Filler, Tooltip, Legend);

const PieChartComponent = ({ array, opt, pieChartTitle }) => {
  const { cioModelBackpackPieChartData } = useSelector((state) => state.cio);
  const [isBarGraph1Visible, setIsBarGraph1Visible] = useState(false);
  const [isBarGraph2Visible, setIsBarGraph2Visible] = useState(false);
  const [barGraphData, setBarGraphData] = useState({});
  const countDeployedBy = (array) => {
    const map = new Map();

    array.forEach((item) => {
      const deployedBy = item.model.deployedBy;
      const modelDisplayName = item.model.displayName;

      if (map.has(deployedBy)) {
        const entry = map.get(deployedBy);
        entry.count += 1;
        entry.displayNames.push(modelDisplayName);
      } else {
        map.set(deployedBy, { count: 1, displayNames: [modelDisplayName] });
      }
    });

    const resultArray = [];
    map.forEach((entry, deployedBy) => {
      resultArray.push({
        deployedBy,
        count: entry.count,
        displayNames: entry.displayNames,
      });
    });

    return resultArray;
  };

  const countBusinessUnits = (array) => {
    const map = new Map();

    array.forEach((item) => {
      const businessUnit = item.model.businessUnit;
      const modelDisplayName = item.model.displayName;

      if (map.has(businessUnit)) {
        const entry = map.get(businessUnit);
        entry.count += 1;
        entry.displayNames.push(modelDisplayName);
      } else {
        map.set(businessUnit, { count: 1, displayNames: [modelDisplayName] });
      }
    });

    const resultArray = [];
    map.forEach((entry, businessUnit) => {
      resultArray.push({
        businessUnit,
        count: entry.count,
        displayNames: entry.displayNames,
      });
    });

    return resultArray;
  };

  const result = countDeployedBy(array);
  const result2 = countBusinessUnits(array);

  const project_names = [
    "Project A",
    "Project B",
    "Project C",
    "Project D",
    "Project E",
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
  ];

  const getRandomCost = () => {
    // Generate a random cost between 1000 and 2000
    return Math.floor(Math.random() * (2000 - 1000 + 1)) + 1000;
  };
  const getRandomROI = () => {
    // Generate a random ROI between -50 and 100
    return Math.floor(Math.random() * (100 - -50 + 1)) + -50;
  };

  const barChart1Data = project_names.map((project_name) => ({
    project_name,
    spent_per_model: getRandomCost(),
    ROI: getRandomROI(),
  }));

  const barChart2Data = months.map((month) => ({
    month,
    cost: getRandomCost(),
    models: result2.displayNames,
  }));

  const chartData = {
    labels: result.map((item) => item.deployedBy),
    datasets: [
      {
        label: "# No. of models deployed",
        data: result.map((item) => item.count),
        fill: true,
        backgroundColor: ["#32CD32", "#FF5E0E", "#00B0F0", "#002147"],
        barGraphData: barChart1Data,
      },
    ],
  };

  const chartData2 = {
    labels: result2.map((item) => item.businessUnit),
    datasets: [
      {
        label: "# Models in Business Unit",
        data: result.map((item) => item.count),
        fill: true,
        backgroundColor: ["#32CD32", "#FF5E0E", "#00B0F0", "#002147"],
        barGraphData: barChart2Data,
      },
    ],
  };

  const chartRef = useRef();
  const setData = (opt, datasetIndexNum, dataPoint) => {
    if (opt === 1) {
      setBarGraphData({
        data: chartData.datasets[datasetIndexNum].barGraphData,
      });
      setIsBarGraph1Visible(true);
      setIsBarGraph2Visible(false);
    } else if (opt === 2) {
      setBarGraphData({
        data: chartData2.datasets[datasetIndexNum].barGraphData,
        color: chartData2.datasets[datasetIndexNum].backgroundColor[dataPoint],
      });
      setIsBarGraph1Visible(false);
      setIsBarGraph2Visible(true);
    }
  };

  const onClick = (e, opt) => {
    if (getElementsAtEvent(chartRef.current, e).length > 0) {
      const datasetIndexNum = getElementsAtEvent(chartRef.current, e)[0]
        .datasetIndex;
      const dataPoint = getElementsAtEvent(chartRef.current, e)[0].index;
      console.log(`Dataset: ${datasetIndexNum} and Data: ${dataPoint}`);
      setData(opt, datasetIndexNum, dataPoint);
    }
  };

  const handleCancel1 = () => {
    setIsBarGraph1Visible(false);
  };
  const handleCancel2 = () => {
    setIsBarGraph2Visible(false);
  };

  return (
    <>
      <Pie
        data={opt === 1 ? chartData : chartData2}
        options={{
          responsive: true,
          plugins: {
            legend: { position: "top", align: "start" },
            title: { display: true, text: pieChartTitle },
          },
        }}
        onClick={(e) => onClick(e, opt)}
        ref={chartRef}
      />
      {isBarGraph1Visible && (
        <LineChartComponent
          handleCancel={handleCancel1}
          barGraphData={barGraphData.data}
          isBarGraphVisible={isBarGraph1Visible}
        />
      )}
      {isBarGraph2Visible && (
        <ModelCostBarGraphComponent
          handleCancel={handleCancel2}
          barGraphData={barGraphData}
          isBarGraphVisible={isBarGraph2Visible}
        />
      )}
    </>
  );
};

export default PieChartComponent;
