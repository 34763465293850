import { DownloadOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const ModelCostBarGraphComponent = ({barGraphData,isBarGraphVisible,handleCancel}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Monthly costs by business unit',
      },
    },
  };
  const graphData = barGraphData.data;

  const labels = graphData.map(item=>item.month);

  const data = {
    labels,
    datasets: [
      {
        label: 'Costs',
        data: graphData.map(item=>item.cost),
        backgroundColor: barGraphData.color,
      }
    ],
  };
  
  return (
    <Modal width={"60%"} open={isBarGraphVisible} footer={false} maskClosable={false} onCancel={handleCancel}>
      <Bar options={options} data={data} />
      <div style={{display:"flex",justifyContent:"end"}}>
        <Button size="large" icon={<DownloadOutlined />}>Export</Button>
      </div>
    </Modal>
  )
}

export default ModelCostBarGraphComponent