import { Badge, Card, Col, Pagination, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./CIODashboard.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getModelBackPackList } from "../../redux/actions/cioDashboard";
import {
  setCIODashboardCardsPerPage,
  setCIODashboardCurrentPage,
} from "../../redux/slices/cioSlice";
import Spinner from "../Spinner/Spinner";

const { Option } = Select;
const { Title } = Typography;

const CIODashboardNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    modelBackpackList,
    cioDashboardCurrentPage,
    cioDashboardCardsPerPage,
    isModelBackpackListLoading,
  } = useSelector((state) => state.cio);
  const departments = ["Marketing","HR","Engineering"];

  const [hoveredCard, setHoveredCard] = useState(null);

  const handleCardClick = (ele,index) => {
    navigate(`backpack/${ele._id}`,{state:{department:departments[index % departments.length]}});
  };

  useEffect(() => {
    dispatch(
      getModelBackPackList({
        companyId: "66347aa1043553711bcc756c",
        page: cioDashboardCurrentPage,
        limit: cioDashboardCardsPerPage,
      })
    );
  }, [dispatch, cioDashboardCurrentPage, cioDashboardCardsPerPage]);

  const handleChangePage = (page) => {
    dispatch(setCIODashboardCurrentPage(page));
    dispatch(
      getModelBackPackList({
        companyId: "66347aa1043553711bcc756c",
        page: page,
        limit: cioDashboardCardsPerPage,
      })
    );
  };

  // const handleChangeCardsPerPage = (value) => {
  //   dispatch(setCIODashboardCardsPerPage(value));
  //   dispatch(setCIODashboardCurrentPage(1));
  //   dispatch(
  //     getModelBackPackList({
  //       companyId: "66347aa1043553711bcc756c",
  //       page: 1,
  //       limit: cioDashboardCardsPerPage,
  //     })
  //   );
  // };
  // Find the max cumulativeSpend
  const maxCumulativeSpend = modelBackpackList && Math.max(...modelBackpackList.map(ele => ele.cumulativeSpend));
  
  const renderCard = (ele, index) => {
    const cardContent = (
      <Card
        hoverable
        className="modelbackpack-card"
        onClick={() => handleCardClick(ele, index)}
        onMouseEnter={() => setHoveredCard(ele._id)}
        onMouseLeave={() => setHoveredCard(null)}
      >
        <div className={`card-container ${hoveredCard === ele._id ? "flipped" : ""}`}>
          <div className="card-front">
            <img
              width={280}
              height={200}
              className="cio-dashboard-backpack-img"
              // src={`https://cloudflare-ipfs.com/ipfs/${ele.image.substr(7)}`}
              src={`https://ipfs.thenftbrewery.com/ipfs/${ele.image.substr(7)}`}
              alt={ele.name}
            />
          </div>
          <div className="card-back">
            <p>Cumulative Spend YTD: ${ele.cumulativeSpend.toFixed(2)}</p>
            <p>BU: {departments[index % departments.length]}</p>
          </div>
        </div>
        <Title level={3} className="cio-dashboard-overview-card-title">
          {ele.name && ele.name.length >= 20 ? `${ele.name.substring(0, 20)}...` : ele.name}
        </Title>
      </Card>
    );

    // Wrap card content with Badge.Ribbon only if it's the top spender
    return (
      <Col key={ele._id} xs={24} sm={24} md={12} lg={8} xl={6}>
        {ele.cumulativeSpend === maxCumulativeSpend ? (
          <Badge.Ribbon color="#00B0F0" text="Top Spender">
            {cardContent}
          </Badge.Ribbon>
        ) : (
          cardContent
        )}
      </Col>
    );
  };

  return (
    <div>
      <Title level={1}>AI Footprint Across the Enterprise</Title>
      <div className="cio-dashboard-metrics-container">
        {isModelBackpackListLoading ? (
          <div className="flex-direction-col width-height-100perc">
            <Spinner />
            <h3>Loading...</h3>
          </div>
        ) : (
          <Row gutter={[20, 20]}>
            {modelBackpackList.map((ele, index) => renderCard(ele, index))}
          </Row>
        )}
      </div>
      <Row justify="end" align="middle" className="mt-1">
        {/* <Col>
          <Select
            value={cioDashboardCardsPerPage}
            onChange={handleChangeCardsPerPage}
          >
            <Option value={12}>12 per page</Option>
            <Option value={20}>20 per page</Option>
            <Option value={40}>40 per page</Option>
            <Option value={100}>100 per page</Option>
          </Select>
        </Col> */}
        <Col>
          <Pagination
            showSizeChanger={false}
            // hideOnSinglePage={true}
            current={cioDashboardCurrentPage}
            onChange={handleChangePage}
            pageSize={cioDashboardCardsPerPage}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CIODashboardNew;
