import React from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useParams } from "react-router-dom";
import { Typography } from "antd";
const {Title} = Typography;
const CIOModelPage = () => {
    const { modelName } = useParams();
    console.log("modelName :",modelName);
    return (
        <MainLayout>
            <Title>{modelName}</Title>
        </MainLayout>
    );
};

export default CIOModelPage;
