import React, { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import { Card, Col, Image, Radio, Row, Space, Typography } from "antd";
import { useLocation, useParams } from "react-router-dom";
import CIODeployedModelBackpacksPipelineView from "../../components/CIODeployedModelBackpacksPipelineView/CIODeployedModelBackpacksPipelineView";
import { useDispatch, useSelector } from "react-redux";
import { getModelBackPackDetails } from "../../redux/actions/cioDashboard";
import Spinner from "../../components/Spinner/Spinner";
import "./CIOModelBackpackPage.scss";
import { LinkOutlined } from "@ant-design/icons";
import PieChartComponent from "../../components/PieChartComponent/PieChartComponent";

const { Title } = Typography;
const CIOModelBackpackPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    modelBackpackDetails,
    isModelBackpackDetailsLoading,
    modelBackpackModelsLists,
  } = useSelector((state) => state.cio);
  const pipelinedModels = modelBackpackModelsLists?.pipelinedModels;
  const uiModels = modelBackpackModelsLists?.uiModels;
  const displayNameArray = uiModels?.map((item) => item.model.displayName);
  const { backpackId } = useParams();
  const [selectedTable, setSelectedTable] = useState(1); // Default selection
  useEffect(() => {
    dispatch(getModelBackPackDetails({ modelBackpackId: backpackId }));
  }, [dispatch, backpackId]);

  const options = [
    {
      label: "Model Garden",
      value: 1,
    },
    {
      label: "Custom Training",
      value: 2,
    },
    {
      label: "AutoML Training",
      value: 3,
    },
  ];

  const onChange = ({ target: { value } }) => {
    setSelectedTable(value);
  };

  // array for rendering pie charts
  const array = [
    {
      model: {
        name: "projects/922246369439/locations/us-central1/models/llama3-70b-chat-001-1714386085909",
        displayName: "llama3-70b-001-1715087212451",
        description: "",
        createTime: {
          seconds: "1714386136",
          nanos: 477178000,
        },
        updateTime: {
          seconds: "1716207701",
          nanos: 894082000,
        },
        deployedBy: "0xd2f52f0d2f6CD440c4724f7d3C349Bc50cd64F23",
        businessUnit: "Engineering",
      },
      _id: "664c83ccfe1359ef3b382356",
    },
    {
      model: {
        name: "projects/922246369439/locations/us-central1/models/llama3-70b-chat-001-1714386085909",
        displayName: "llama3-70b",
        description: "",
        createTime: {
          seconds: "1714386136",
          nanos: 477178000,
        },
        updateTime: {
          seconds: "1716207701",
          nanos: 894082000,
        },
        deployedBy: "0xfde03420d2f6CD440c4724f7d3C349Bc50cg3r32",
        businessUnit: "Marketing",
      },
      _id: "664c83ccfe1359ef3b382356",
    },
    {
      model: {
        name: "projects/922246369439/locations/us-central1/models/llama3-70b-chat-001-1714386085909",
        displayName: "llama3-70b-model",
        description: "",
        createTime: {
          seconds: "1714386136",
          nanos: 477178000,
        },
        updateTime: {
          seconds: "1716207701",
          nanos: 894082000,
        },
        deployedBy: "0xfe23ddc22f6CD440c4724f7d3C349Bcsdw3ecw1",
        businessUnit: "Sales",
      },
      _id: "664c83ccfe1359ef3b382356",
    },
    {
      model: {
        name: "projects/922246369439/locations/us-central1/models/llama3-70b-chat-001-1714386085909",
        displayName: "llama3-70billion",
        description: "",
        createTime: {
          seconds: "1714386136",
          nanos: 477178000,
        },
        updateTime: {
          seconds: "1716207701",
          nanos: 894082000,
        },
        deployedBy: "0xd2f52f0d2f6CD440c4724f7d3C349Bc50cd64F23",
        businessUnit: "Engineering",
      },
      _id: "664c83ccfe1359ef3b382356",
    },
  ];

  return (
    <MainLayout>
      {isModelBackpackDetailsLoading ? (
        <div className="flex-direction-col width-height-100perc">
          <Spinner />
          <h3>Loading...</h3>
        </div>
      ) : (
        <div className="cio-dashboard-backpack-details-container">
          <Row className="mt-2rem" justify={"space-between"} gutter={[20, 20]}>
            <Col span={20}>
              <Title>
                {
                  modelBackpackDetails.backpackNFTDetail?.jsonDetails[0]
                    .jsonDetails.name
                }
                &nbsp;Deployments
              </Title>

              <Title level={3}>Description</Title>
              {modelBackpackDetails.backpackNFTDetail?.jsonDetails[0].jsonDetails.description
                ?.split("\n")
                .map((line, index) => (
                  <p key={index}>{line}</p>
                ))}

              <Title level={3}>Attributes</Title>
              <Row gutter={[40, 16]}>
                {modelBackpackDetails.backpackNFTDetail?.jsonDetails[0].jsonDetails.attributes.map(
                  (attribute, index) => (
                    <Col key={index} xs={24} sm={12} md={12} lg={18} xl={6}>
                      <div className="cio-dashboard-backpack-details-attribute-container">
                        <div className="cio-dashboard-backpack-details-attribute-title">
                          {attribute.trait_type}
                        </div>
                        <div>{attribute.value}</div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </Col>
            <Col span={4}>
              <div className="cio-dashboard-backpack-image-container">
                {isModelBackpackDetailsLoading ? (
                  <div className="cio-dashboard-backpack-image-container-spinner-container">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <Image
                      width={200}
                      height={150}
                      // src={`https://cloudflare-ipfs.com/ipfs/${modelBackpackDetails.backpackNFTDetail?.jsonDetails[0].jsonDetails.image.substr(
                      //   7
                      // )}`}
                      src={`https://ipfs.thenftbrewery.com/ipfs/${modelBackpackDetails.backpackNFTDetail?.jsonDetails[0].jsonDetails.image.substr(
                        7
                      )}`}
                    />
                    <Space direction="horizontal">
                      <Title
                        className="tba-address"
                        level={5}
                        copyable={{ text: modelBackpackDetails.TBA_address }}
                      >
                        {modelBackpackDetails.TBA_address?.slice(0, 6)}...
                        {modelBackpackDetails?.TBA_address?.slice(-4)}
                      </Title>
                      <a
                        href={
                          modelBackpackDetails.backpackNFTDetail?.jsonDetails[0]
                            .jsonDetails.animation_url
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkOutlined />
                      </a>
                    </Space>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Card className="cio-dashboard-backpack-details-piechart-card">
                <PieChartComponent
                  array={array}
                  opt={1}
                  pieChartTitle={"Model Deployers"}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card className="cio-dashboard-backpack-details-piechart-card">
                <PieChartComponent
                  array={array}
                  opt={2}
                  pieChartTitle={"Business Units"}
                />
              </Card>
            </Col>
          </Row>
          <Radio.Group
            className="mt-1rem"
            options={options}
            onChange={onChange}
            value={selectedTable}
            optionType="button"
          />
          <CIODeployedModelBackpacksPipelineView
            selectedButton={selectedTable}
            department={location.state.department}
          />
        </div>
      )}
    </MainLayout>
  );
};

export default CIOModelBackpackPage;
