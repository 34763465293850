import { createSlice } from "@reduxjs/toolkit";
import { getModelBackPackDetails, getModelBackPackList, getModelBackpackModelsList, getModelBackpackNFTDetails, getModelCuratorModels } from "../actions/cioDashboard";

const initialState= {
    isLoggedIn: true,
    isCIOModelBackpackModalOpen:false,
    modelName:"",
    modelBackpackList:[],
    isModelBackpackListLoading:false,
    cioDashboardCurrentPage:1,
    cioDashboardCardsPerPage:8,
    modelBackpackDetails:{},
    isModelBackpackDetailsLoading:false,
    modelBackpackModelsLists:{},
    isModelBackpackModelsListLoading:false,
    modelBackpackNFTDetails:{},
    isModelBackpackNFTDetailsLoading:false,
    cioModelBackpackPieChartData:{},
    isModelCuratorModelsListLoading:false,
    modelCuratorModelsList:[]
};

const cioSlice = createSlice({
    name:"cio",
    initialState: initialState,
    reducers:{
        setIsLoggedIn:(state,action)=>{
            state.isLoggedIn = true
        },
        setIsCIOModelBackpackModalOpen:(state,action)=>{
            state.isCIOModelBackpackModalOpen = !state.isCIOModelBackpackModalOpen
        },
        showCIOModelBackpackModal:(state,{payload})=>{
            state.isCIOModelBackpackModalOpen = true;
            console.log("payload :",payload);
            state.modelName = payload;
        },
        handleCancelCIOModelBackpackModal:(state,action)=>{
            state.isCIOModelBackpackModalOpen = false;
            state.modelName = "";
        },    
        setCIODashboardCurrentPage : (state,{payload})=>{
            state.cioDashboardCurrentPage = payload;
        },
        setCIODashboardCardsPerPage : (state,{payload})=>{
            state.cioDashboardCardsPerPage = payload;
        },
        setCIOModelBackpackPieChartData :  (state,{payload})=>{
            state.cioModelBackpackPieChartData = payload;
        },
    },
    extraReducers:(builder) => {
        builder
            .addCase(getModelBackPackList.pending, (state) => {
                state.isModelBackpackListLoading = true;
                state.modelBackpackList = [];
            })
            .addCase(getModelBackPackList.fulfilled, (state, { payload }) => {
                state.modelBackpackList = payload;
                state.isModelBackpackListLoading = false;
            })
            .addCase(getModelBackPackList.rejected, (state, { payload }) => {
                state.modelBackpackList = [];
                state.isModelBackpackListLoading = false;
            })
            .addCase(getModelBackPackDetails.pending, (state) => {
                state.modelBackpackDetails = {};
                state.isModelBackpackDetailsLoading = true;
            })
            .addCase(getModelBackPackDetails.fulfilled, (state, { payload }) => {
                state.modelBackpackDetails = payload;
                state.isModelBackpackDetailsLoading = false;
            })
            .addCase(getModelBackPackDetails.rejected, (state, { payload }) => {
                state.modelBackpackDetails = {};
                state.isModelBackpackDetailsLoading = false;
            })
            .addCase(getModelBackpackModelsList.pending, (state) => {
                state.isModelBackpackModelsListLoading = true;
                state.modelBackpackModelsLists = {};
            })
            .addCase(getModelBackpackModelsList.fulfilled, (state, { payload }) => {
                state.modelBackpackModelsLists = payload;
                state.isModelBackpackModelsListLoading = false;
            })
            .addCase(getModelBackpackModelsList.rejected, (state, { payload }) => {
                state.modelBackpackModelsLists = {};
                state.isModelBackpackModelsListLoading = false;
            })
            .addCase(getModelBackpackNFTDetails.pending, (state) => {
                state.isModelBackpackNFTDetailsLoading= true;
                state.modelBackpackNFTDetails = {};
            })
            .addCase(getModelBackpackNFTDetails.fulfilled, (state, { payload }) => {
                state.isModelBackpackNFTDetailsLoading= false;
                state.modelBackpackNFTDetails = payload;
            })
            .addCase(getModelBackpackNFTDetails.rejected, (state, { payload }) => {
                state.modelBackpackNFTDetails = {};
                state.isModelBackpackNFTDetailsLoading = false;
            })
            .addCase(getModelCuratorModels.pending, (state) => {
                state.isModelCuratorModelsListLoading = true;
                state.modelCuratorModelsList = [];
            })
            .addCase(getModelCuratorModels.fulfilled, (state, { payload }) => {
                state.isModelCuratorModelsListLoading = false;
                state.modelCuratorModelsList = payload;
            })
            .addCase(getModelCuratorModels.rejected, (state, { payload }) => {
                state.isModelCuratorModelsListLoading = false;
                state.modelCuratorModelsList = [];
            })
    }
});
export const {setIsLoggedIn,setIsCIOModelBackpackModalOpen,showCIOModelBackpackModal,handleCancelCIOModelBackpackModal,setCIODashboardCurrentPage,setCIODashboardCardsPerPage} = cioSlice.actions;
export default cioSlice.reducer;