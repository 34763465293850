import React from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useLocation } from "react-router-dom";
import { Col, Image, Row, Table, Typography } from "antd";
import { EyeOutlined, LinkOutlined, SendOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import CIOModelBackpackModal from "../../components/CIOModelBackpackModal/CIOModelBackpackModal";
import { showCIOModelBackpackModal } from "../../redux/slices/cioSlice";
const { Title } = Typography;
const CIOBackpackPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const backpack = location.state.backpack;
  const {isCIOModelBackpackModalOpen} = useSelector(state=>state.cio);
  const data = [
    {
      key: 1,
      modelName: "Mistral",
      workflowUId:1,
      workflowName: "Workflow 1",
      modelVersionCount: 2,
      modelCurrentVersion: 2,
    },
    {
      key: 2,
      modelName: "Claude",
      workflowUId:2,
      workflowName: "Workflow 2",
      modelVersionCount: 1,
      modelCurrentVersion: 1,
    },
    {
      key: 3,
      modelName: "Claude",
      workflowUId:3,
      workflowName: "Workflow 3",
      modelVersionCount: 1,
      modelCurrentVersion: 1,
    },
    {
      key: 4,
      modelName: "GPT-3.5",
      workflowUId:4,
      workflowName: "Workflow 4",
      modelVersionCount:1,
      modelCurrentVersion:1
    },
  ];

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (_,item) =>item.key
    },
    {
      title: "Model Name",
      dataIndex: "modelName",
      key: "modelName",
      render: (_, item) => {
        return item.modelName;
      },
    },
    {
        title: "Workflow Name",
        dataIndex: "workflowName",
        key: "workflowName",
        render: (_, item) => {
          return item.workflowName;
        },
    },
    {
      title: "Total Model Versions",
      dataIndex: "modelVersionCount",
      key: "modelVersionCount",
      render: (_, item) => {
        return item.modelVersionCount;
      },
    },
    {
        title: "Current Model Version",
        dataIndex: "modelCurrentVersion",
        key: "modelCurrentVersion",
        render: (_, item) => {
          return item.modelCurrentVersion;
        },
    },
    {
      title: "More",
      dataIndex: "more",
      key: "more",
      render: (_, item) => {
        return (
          <EyeOutlined
            style={{ color: "blue" }}
            onClick={() => {
                dispatch(showCIOModelBackpackModal(`More Details for ${item.modelName} model of ${item.workflowName} workflow`))
            }}
          />
        );
      },
    },
  ];

  return (
    <MainLayout>
      <Row
        style={{ marginTop: "2rem" }}
        justify={"space-between"}
        gutter={[20, 20]}
      >
        <Col>
          <Title>{backpack.name}</Title>
          <Title level={3}>{backpack.description}</Title>
          <Title level={5}>
            Created by :{" "}
            <span
              onClick={(e) => {
                window.location.href = `mailto:${backpack.creator}`;
              }}
            >
              <a>{backpack.creator}</a>
            </span>
          </Title>
        </Col>
        <Col style={{backgroundColor:"#ffffff",padding:"10px"}}>
          <Image
            width={200}
            src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
          />
          <Title level={5} copyable={true}>0xBackpackAddress <a href={`https://testnets.opensea.io/0xBackpackAddress`} target="_blank" rel="noreferrer"><LinkOutlined/></a></Title>
        </Col>
      </Row>
      <Table dataSource={data} columns={columns}/>
      {isCIOModelBackpackModalOpen && <CIOModelBackpackModal />}
    </MainLayout>
  );
};

export default CIOBackpackPage;
