import { DownloadOutlined } from "@ant-design/icons";
import { Button, Modal, Select } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useState } from "react";

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChartComponent = ({
  barGraphData,
  handleCancel,
  isBarGraphVisible,
}) => {
  const [leftSelectBoxValue,setLeftSelectBoxValue] = useState("quarterly");
  const [rightSelectBoxValue,setRightSelectBoxValue] = useState(null);
  const getRandomCost = () => {
    // Generate a random cost between 1200 and 3000
    return Math.floor(Math.random() * (3000 - 1200 + 1)) + 1200;
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: "Model Deployer's spends across projects",
      },
    },
  };
  const labels = barGraphData.map((item) => item.project_name);
  const defaultDataset = [
    {
      label: "Spent Per Project",
      data: barGraphData.map((item) =>leftSelectBoxValue === "quarterly" ? item.spent_per_model : leftSelectBoxValue === "yearly" ? item.spent_per_model + getRandomCost() : item.spent_per_model ),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      yAxisID: "y",
    }
  ]
  const months = [
    "January", "February", "March", "April", "May", "June", "July", "August"
  ];

  const monthBasedDataset = [
    {
      label: "Spent Per Month",
      data: months.map((month) =>getRandomCost()),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      yAxisID: "y",
    }
  ]


  const data = {
    labels:rightSelectBoxValue===null?labels:months,
    datasets: rightSelectBoxValue===null?defaultDataset:monthBasedDataset,
  };

  const onChange = (value) => {
    console.log("onchange triggered")
    setRightSelectBoxValue(null);
    setLeftSelectBoxValue(value)
  };

  const onChange2 = (value) => {
    console.log(`selected ${value}`);
    setRightSelectBoxValue(value);
  };
  const onSearch2 = (value) => {
    console.log("search:", value);
  };

  return (
    <Modal
      width={"60%"}
      open={isBarGraphVisible}
      footer={false}
      maskClosable={false}
      onCancel={handleCancel}
    >
      <div className="mt-2rem flex-between">
        <Select
          defaultValue={leftSelectBoxValue}
          value={leftSelectBoxValue}
          onChange={onChange}
          style={{
            width: 200,
          }}
          options={[
            {
              value: "quarterly",
              label: "Quarterly",
            },
            {
              value: "yearly",
              label: "Yearly",
            },
          ]}
        />
        <Select
          style={{
            width: 200,
          }}
          value={rightSelectBoxValue && rightSelectBoxValue}
          showSearch
          placeholder={"Select from available views"}
          optionFilterProp="label"
          onChange={onChange2}
          onSearch={onSearch2}
          options={[
            {
              value: "per_month",
              label: "Monthly Spends",
            },
            {
              value: "model_view",
              label: "Model Costs Per Month",
            }
          ]}
        />
      </div>
      <Line options={options} data={data} />
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button size="large" icon={<DownloadOutlined />}>
          Export
        </Button>
      </div>
    </Modal>
  );
};

export default LineChartComponent;
