import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getModelCuratorModels } from '../../redux/actions/cioDashboard';
import MainLayout from '../../components/MainLayout/MainLayout';
import Spinner from '../../components/Spinner/Spinner';
import { List, Typography } from 'antd';
const {Title} = Typography;

const CIOModelsByModelCurator = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { modelCuratorModelsList,isModelCuratorModelsListLoading } = useSelector(state => state.cio);
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    useEffect(() => {
        dispatch(getModelCuratorModels({ modelCuratorEmail: email }));
    }, [dispatch, email]);
    console.log("modelCuratorModelsList :",modelCuratorModelsList);
    return (
        <MainLayout>
        {
            isModelCuratorModelsListLoading ?
            <div className="flex-direction-col width-height-100perc">
                <Spinner />
                <h3>Loading...</h3>
            </div>
            :
            <div>
                <Title>Models deployed by {email}</Title>
                <List
                    bordered
                    dataSource={modelCuratorModelsList}
                    renderItem={(item) => (
                        <List.Item>
                            {item.modelId}
                        </List.Item>
                    )}
                />
            </div>

        }
        </MainLayout>
    )
}

export default CIOModelsByModelCurator