import React, { useEffect } from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getModelBackpackNFTDetails } from "../../redux/actions/cioDashboard";
import Spinner from "../../components/Spinner/Spinner";
import { Col, Image, Row , Typography} from "antd";
const {Title} = Typography;

const CIOBackpacksModelNFTPage = () => {
  const { modelId } = useParams();
  console.log("modelId :",modelId);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getModelBackpackNFTDetails({ modelId: modelId }));
  }, [dispatch, modelId]);
  const { modelBackpackNFTDetails, isModelBackpackNFTDetailsLoading } =
    useSelector((state) => state.cio);
  console.log("answer  ", modelBackpackNFTDetails);
  return (
    <MainLayout>
      {isModelBackpackNFTDetailsLoading ? (
        <div className="flex-direction-col width-height-100perc">
          <Spinner />
          <h3>Loading...</h3>
        </div>
      ) : (
        <div className="cio-dashboard-backpack-details-container">
          <Row
            style={{ marginTop: "2rem" }}
            justify={"space-between"}
            gutter={[20, 20]}
          >
            <Col span={20}>
              <Title>{modelBackpackNFTDetails?.name}</Title>

              <Title level={3}>Description</Title>
              {modelBackpackNFTDetails?.description
                ?.split("\n")
                .map((line, index) => (
                  <p key={index}>{line}</p>
                ))}

              <Title level={3}>Attributes</Title>
              <Row gutter={[40, 16]}>
                {modelBackpackNFTDetails.attributes?.map((attribute, index) => (
                  <Col key={index} xs={24} sm={12} md={12} lg={18} xl={6}>
                    <div
                      style={{
                        width: "250px",
                        border: "2px solid gray",
                        borderRadius: "5px",
                        padding: "10px",
                        boxSizing: "border-box",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>
                        {attribute?.trait_type}
                      </div>
                      <div>{attribute?.value}</div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={4}>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {isModelBackpackNFTDetailsLoading ? (
                  <div
                    style={{
                      height: "200px",
                      width: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <Image
                      width={200}
                      height={150}
                      // src={`https://cloudflare-ipfs.com/ipfs/${modelBackpackNFTDetails.image?.substr(
                      //   7
                      // )}`}
                      src={`https://ipfs.thenftbrewery.com/ipfs/${modelBackpackNFTDetails.image?.substr(
                        7
                      )}`}

                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </MainLayout>
  );
};

export default CIOBackpacksModelNFTPage;
