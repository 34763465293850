import { Col, Image, Modal, Row, Table, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleCancelCIOModelBackpackModal } from "../../redux/slices/cioSlice";
import { LinkOutlined } from "@ant-design/icons";
const { Title } = Typography;

const CIOModelBackpackModal = () => {
  const dispatch = useDispatch();
  const { modelName, isCIOModelBackpackModalOpen } = useSelector(
    (state) => state.cio
  );
  const handleCancel = () => {
    dispatch(handleCancelCIOModelBackpackModal());
  };
  const data = [
    {
      key: 1,
      submittedBy: "datasetprovider1@gmail.com",
      approvedBy: "datasetapprover1@gmail.com",
      datasetHash:"0xDataset1Hash"
    },
    {
      key: 2,
      submittedBy: "datasetprovider2@gmail.com",
      approvedBy: "datasetapprover2@gmail.com",
      datasetHash:"0xDataset2Hash"

    },
    {
      key: 3,
      submittedBy: "datasetprovider3@gmail.com",
      approvedBy: "datasetapprover3@gmail.com",
      datasetHash:"0xDataset3Hash"
    }
  ];

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (_, item) => item.key,
    },
    {
        title: "Dataset Hash",
        dataIndex: "datasetHash",
        key: "datasetHash",
        render: (_, item) => item.datasetHash,
    },
    {
      title: "Submitted by",
      dataIndex: "submittedBy",
      key: "submittedBy",
      render: (_, item) => {
        return item.submittedBy;
      },
    },
    {
      title: "Approved by",
      dataIndex: "approvedBy",
      key: "approvedBy",
      render: (_, item) => {
        return item.approvedBy;
      },
    },
  ];

  return (
    <Modal
      width={"90%"}
      footer={false}
      title={modelName}
      open={isCIOModelBackpackModalOpen}
      onCancel={handleCancel}
    >
      <Row justify={"space-between"}>
        <Col>
            <Title level={5}>Model NFT Contract Address : 0xModelNFTContractAddress</Title>
            <Title level={5}>Approved Datasets : 3</Title>
        </Col>
        <Col style={{ backgroundColor: "#cccccc" , padding:"10px"}}>
          <Image
            width={200}
            src="https://gw.alipayobjects.com/zos/antfincdn/aPkFc8Sj7n/method-draw-image.svg"
          />
          <Title level={5} copyable={true}>
            0xModelBackpackAddress{" "}
            <a
              href={`https://testnets.opensea.io/0xModelBacpackAddress`}
              target="_blank"
              rel="noreferrer"
            >
              <LinkOutlined />
            </a>
          </Title>
        </Col>
      </Row>
      <Table dataSource={data} columns={columns} />
    </Modal>
  );
};

export default CIOModelBackpackModal;
