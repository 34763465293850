import React from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import CIOLoginForm from "../../components/CIOLoginForm/CIOLoginForm";
import { useSelector } from "react-redux";
import CIODashboardNew from "../../components/CIODashboardNew/CIODashboardNew";

const CIODashboardPage = () => {
  const { isLoggedIn } = useSelector((state) => state.cio);

  return (
    <MainLayout>
      {isLoggedIn ? <CIODashboardNew /> : <CIOLoginForm />}
    </MainLayout>
  );
};

export default CIODashboardPage;
