import { Table, Tooltip } from "antd";
import React from "react";
import {Typography} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { unixEpochToIST } from "../../utils/dateFormatter";
import Spinner from "../Spinner/Spinner";
const {Text} = Typography;
const CIODeployedModelBackpacksPipelineView = ({selectedButton,department }) => {
    const navigate = useNavigate();
    const {modelBackpackModelsLists,isModelBackpackModelsListLoading} = useSelector(state => state.cio);
    const pipelinedModels = modelBackpackModelsLists?.pipelinedModels;
    const uiModels = modelBackpackModelsLists?.uiModels;
    console.log("uimodels :",uiModels);
    const trainingDataHash = "-";
    const deployedBy = "0xd2f52f0d2f6CD440c4724f7d3C349Bc50cd64F23";
    const columns1 = [
        // {
        //   title: "Index",
        //   dataIndex: "index",
        //   key: "index",
        //   render: (_, item) => item.key,
        //   fixed:"left",
        //   ellipses:true
        // },
        {
          title: "Pipeline Name",
          dataIndex: "pipelineName",
          key: "pipelineName",
          render: (_, item) => {
            return item.pipelineName;
          },
          ellipses:true
        },
        {
          title: "Created",
          dataIndex: "created",
          key: "created",
          render: (_, item) => {
            return <>{unixEpochToIST(item.model.createTime.seconds)}</>;
          },
          ellipses:true
        },
        {
          title: "Ended",
          dataIndex: "ended",
          key: "ended",
          render: (_, item) => {
            return <>{unixEpochToIST(item.model.updateTime.seconds)}</>;
          },
          ellipses:true
        },
        {
          title: "Business Unit",
          dataIndex: "businessUnit",
          key: "businessUnit",
          render: (_, item) => {
            return "Marketing";
          },
          ellipses:true
        },
        {
          title: "Cumulative Spend YTD",
          dataIndex: "cumulativeSpendYTD",
          key: "cumulativeSpendYTD",
          render: (_, item) => {
            return "$152.35";
          },
          ellipses:true
        },
        {
          title: "Model Hash",
          dataIndex: "modelHash",
          key: "modelHash",
          render: (_,item) => (
            <Text>
              {item.modelHash.slice(0, 6)}...{item.modelHash.slice(-4)}
            </Text>        
          ),
          ellipses:true
        },
        {
          title: "Training Data Hash",
          dataIndex: "trainingDataHash",
          key: "trainingDataHash",
          render: () => (
            <Text>
              {trainingDataHash}
            </Text>        
          ),
          ellipses:true
        },
        {
          title: "Deployed By",
          dataIndex: "deployedBy",
          key: "deployedBy",
          render: () => (
            <Text copyable={{text:deployedBy}}>
              <span>{deployedBy.slice(0, 6)}...{deployedBy.slice(-4)}</span>
            </Text>        
          ),
          ellipses:true
        },
        {
          title: "More",
          dataIndex: "more",
          key: "more",
          render: (_, item) => {
            return (
              <SendOutlined
                style={{ color: "blue" }}
                onClick={() =>
                  navigate(`${item.key}`, { state: { backpack: item } })
                }
              />
            );
          },
          ellipses:true
        },
    ];
    const columns2 = [
      // {
      //   title: "Index",
      //   dataIndex: "index",
      //   key: "index",
      //   render: (_, item) => item.key,
      //   fixed:"left",
      //   ellipses:true
      // },
      {
        title: "Model Name",
        dataIndex: "modelName",
        key: "modelName",
        render: (_, item) => {
          return item.model.displayName;
        },
        ellipses:true
      },
      {
        title: "Created",
        dataIndex: "created",
        key: "created",
        render: (_, item) => {
          return <>{unixEpochToIST(item.model.createTime.seconds)}</>;
        },
        ellipses:true
      },
      {
        title: "Updated",
        dataIndex: "updated",
        key: "updated",
        render: (_, item) => {
          return <>{unixEpochToIST(item.model.updateTime.seconds)}</>;
        },
        ellipses:true
      },
      {
        title: "Business Unit",
        dataIndex: "businessUnit",
        key: "businessUnit",
        render: (_, item) => {
          return department && department;
        },
        ellipses:true
      },
      {
        title: "Cumulative Spend YTD ( USD )",
        dataIndex: "cumulativeSpendYTD",
        key: "cumulativeSpendYTD",
        render: (_, item) => {
          return item.cumulativeSpendYTD;
        },
        ellipses:true
      },
      {
        title: "Model Hash",
        dataIndex: "modelHash",
        key: "modelHash",
        render: (_,item) => (
          <Tooltip title={item.modelHash}>
            <span>{item.modelHash.slice(0, 6)}...{item.modelHash.slice(-4)}</span>
          </Tooltip>        
        ),
        ellipses:true
      },
      {
        title: "Training Data Hash",
        dataIndex: "trainingDataHash",
        key: "trainingDataHash",
        render: () => (
          <Text>
            {trainingDataHash}
          </Text>        
        ),
        ellipses:true
      },
      {
        title: "Deployed By",
        dataIndex: "deployedBy",
        key: "deployedBy",
        render: (_,item) => (
          item.principalEmail?<Text copyable={{text:item.principalEmail}}>
            <span>{item.principalEmail}</span>
          </Text> : "-"      
        ),
        ellipses:true
      },
      {
        title: "Model Curator",
        dataIndex: "modelCurator",
        key: "modelCurator",
        render: (_,item) => (
          item.principalEmail?<Text copyable={{text:item.principalEmail}}>
            <Link to={`/modelCurator?email=${item.principalEmail}`}>{item.principalEmail}</Link>
          </Text> : "-"      
        ),
        ellipses:true
      },
      {
        title: "Data Curator",
        dataIndex: "dataCurator",
        key: "dataCurator",
        render: (_,item) => (
          item.principalEmail?<Text copyable={{text:item.principalEmail}}>
            <span>{item.principalEmail}</span>
          </Text> : "-"      
        ),
        ellipses:true
      },

      {
        title: "More",
        dataIndex: "more",
        key: "more",
        render: (_, item) => {
          return (
            <SendOutlined
              style={{ color: "blue" }}
              onClick={() => navigate(`${item._id}`)}
            />
          );
        },
        ellipses:true
      },
    ];
    return (
      <>      
        {
          isModelBackpackModelsListLoading ? <Spinner />:
          <>
            <Table
              dataSource={selectedButton===1?uiModels:pipelinedModels}
              rowKey={(record) => record.model.name}
              columns={selectedButton === 1 ? columns2 : columns1}
              scroll={{ x: "max-content" }}
            />
          </>
        }
      </>        
    );
};

export default CIODeployedModelBackpacksPipelineView;
