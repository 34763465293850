import "./Header.scss";
import { Button, Space } from "antd";
import CustomUserAvatar from "./CustomUserAvatar";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/web3Auth";
import LinkDropdown from "./LinkDropdown";
import Cookies from "js-cookie";
import SessionTimer from "../SessionTimer/SessionTimer";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const sessionCookie = Cookies.get("session");
  const { isAuthLoading } = useSelector((state) => state.auth);

  const sessionId = JSON.parse(
    localStorage.getItem("openlogin_store")
  )?.sessionId;
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));
  const toSuperAdminDashboard = () => {
    navigate("/superadmin-dashboard");
  };
  const toUserDashboard = () => {
    navigate("/dashboard");
  };

  const toAdminDashboard = () => {
    navigate("/admin-dashboard");
  };

  const userLogIn = () => {
    dispatch(login()).then((res) => {
      res.payload === "superadmin"
        ? toSuperAdminDashboard()
        : res.payload === "admin"
        ? toAdminDashboard()
        : toUserDashboard();
    });
  };

  return (
    <>
      <div className="header">
        <div className="header-right">
          <a href="/">
            <img
              src="/assets/svgs/logo-no-background.svg"
              alt="brand logo"
              className="brand-logo"
            />
          </a>

          {isAdmin && (
            <div className="admin-link-dropdown">
              <LinkDropdown />
            </div>
          )}
        </div>
        <div className="header-left">
          <div className="blockchain-logo">
            <Space>
              <img
                style={{ width: "25px", height: "25px" }}
                src="/assets/svgs/base-logo.svg"
                alt="blockchain-logo"
              />
              <div>Base Sepolia</div>
            </Space>
          </div>
        </div>
        {/* {sessionCookie === "active" && <SessionTimer />} */}
        {/* {currentLocation && currentLocation.pathname.indexOf("/cio-dashboard") === 0 ? (
          <></>
        ) : !sessionId || sessionId === "" ? (
          <div className="header-left">
            <Button
              className="login-btn"
              type="primary"
              size="large"
              onClick={userLogIn}
              loading={isAuthLoading}
            >
              Log In{" "}
            </Button>
          </div>
        ) : (
          <div className="header-left-loggedin">
            <CustomUserAvatar />
          </div>
        )} */}
      </div>
    </>
  );
};
export default Header;
