import React from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { setIsLoggedIn } from '../../redux/slices/cioSlice';
import "./CIOLoginForm.scss";

const CIOLoginForm = () => {
    const dispatch = useDispatch();

    const onFinish = (values) => {
        dispatch(setIsLoggedIn());
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='flex-direction-col w-600 height-100perc'>
            <div className='cio-form-container flex-direction-col'>
                <h1>CIO Login</h1>
                <Form
                    labelCol={{
                    span: 24,
                    }}
                    wrapperCol={{
                    span: 24,
                    }}
                    style={{
                        minWidth: 600,
                    }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                        <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                            required: true,
                            message: 'Please input your username!',
                            },
                        ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                            required: true,
                            message: 'Please input your password!',
                            },
                        ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                        >
                        <Button type="primary" htmlType="submit">
                            Login
                        </Button>
                        </Form.Item>
                </Form>
            </div>
        </div>
    );

}

export default CIOLoginForm