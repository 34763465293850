import React, { useState } from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import { Table, Typography } from "antd";
import { EyeOutlined, SendOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const CIOModelsInUsePage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const [paginationSize, setPaginationSize] = useState(25); //your current default pagination size 25

  const backpackArray = [
    {
      name: "Mistral",
      modelCount: 4,
      workflowCount:4
    },
    {
      name: "Claude",
      modelCount: 2,
      workflowCount:2
    },
    {
      name: "Llama2",
      modelCount: 10,
      workflowCount:10
    },
    {
      name: "GPT-3",
      modelCount: 12,
      workflowCount:12
    },
  ];

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, item) => {
        return item.name;
      },
    },
    {
      title: "Workflow Count",
      dataIndex: "workflowCount",
      key: "workflowCount",
      render: (_, item) => {
        return item.workflowCount;
      },
    },
    {
      title: "More",
      dataIndex: "more",
      key: "more",
      render: (_, item) => {
        return (
          <SendOutlined
            style={{ color: "blue" }}
            onClick={() => navigate(`${item.name}`)}
          />
        );
      },
    },
  ];
  return (
    <MainLayout>
      <Title>Models In Use</Title>
      <Table rowKey={(record) => record.name} dataSource={backpackArray} columns={columns} />
    </MainLayout>
  );
};

export default CIOModelsInUsePage;