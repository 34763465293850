import React , {useEffect,useState} from 'react'
import axios from 'axios'
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from "chart.js";

import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const Test = () => {
    const [chartData,setChartData] = useState({});
    useEffect(()=>{
        const fetchData = async() => {
            const {data} = await axios.get("https://dev.backend.drops.thenftbrewery.com/api/cioDashboard/getAllDeployedModelList/664727153f30ed11a865afc7");
            setChartData({
                labels:data.data.map((item)=>item.model.displayName),
                datasets:[
                    {
                        label:"Model Name",
                        data:data.data.map(item=>item.model.displayName),
                        fill:true,
                        borderColor:"rgb(255,99,132)",
                        backgroundColor:"rgb(255,99,132,0.3)"
                    }
                ]
            })
        }  
        fetchData(); 
    },[])
    return (
        <div>
            {
                chartData && chartData.datasets && (
                    <Pie
                        data={chartData}
                        options={{
                            responsive:true,
                            plugins:{
                                legend:{position:"top"},
                                title:{display:true,text:"Best Seller"}
                            }
                        }}
                    />
                )
            }
        </div>
    )
}

export default Test