import React, { useState } from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import { Table, Typography } from "antd";
import { EyeOutlined, SendOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const CIOBackpacksPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const [paginationSize, setPaginationSize] = useState(25); //your current default pagination size 25

  const backpackArray = [
    {
      uId:1234,
      name: "Retail AI models Backpack",
      description: "Retail AI models Backpack Description",
      creator: "admin1@yopmail.com",
      modelCount: 12,
    },
    {
      uId:5678,
      name: "Marketing AI models Backpack",
      description: "Marketing AI models Backpack Description",
      creator: "admin2@yopmail.com",
      modelCount: 20,
    },
    {
      uId:9012,
      name: "Engineering AI models Backpack",
      description: "Engineering AI models Backpack Description",
      creator: "admin3@yopmail.com",
      modelCount: 15,
    },
  ];

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, item) => {
        return item.name;
      },
    },
    {
      title: "Creator",
      dataIndex: "creator",
      key: "creator",
      render: (_, item) => {
        return item.creator;
      },
    },
    {
      title: "More",
      dataIndex: "more",
      key: "more",
      render: (_, item) => {
        return (
          <SendOutlined
            style={{ color: "blue" }}
            onClick={() => navigate(`${item.uId}`,{state:{backpack:item}})}
          />
        );
      },
    },
  ];
  return (
    <MainLayout>
      <Title>Backpacks</Title>
      <Table
        rowKey={(record) => record.name}
        dataSource={backpackArray}
        columns={columns}
      />
    </MainLayout>
  );
};

export default CIOBackpacksPage;
