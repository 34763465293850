import { configureStore } from "@reduxjs/toolkit";
import {combineReducers} from "@reduxjs/toolkit";

import web3AuthReducer from "./redux/slices/web3AuthSlice";
import eWarrantReducer from "./redux/slices/eWarrantSlice";
import templateSlice from "./redux/slices/templateSlice";
import eWarrantDeployerSlice from "./redux/slices/eWarrantDeployerSlice";
import erc4907ContractSlice from "./redux/slices/erc4907ContractSlice";
import sessionSlice from "./redux/slices/sessionSlice";
import adminReducer from "./redux/slices/adminSlice";
import attestationsReducer from './redux/slices/attestationsSlice';
import superAdminReducer from './redux/slices/superAdminSlice';
import userReducer from './redux/slices/userSlice';
import cioReducer from './redux/slices/cioSlice';

const reducer = combineReducers({
    auth : web3AuthReducer,
    contract : eWarrantReducer,
    admin:adminReducer,
    template:templateSlice,
    eWarrantDeployer : eWarrantDeployerSlice,
    erc4907Contract:erc4907ContractSlice,
    session:sessionSlice,
    attestations:attestationsReducer,
    superAdmin:superAdminReducer,
    user:userReducer,
    cio:cioReducer
})

const store = configureStore({
    reducer : reducer,
})
export default store;
