import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch } from "react-redux";

import PrivateRoutes from "./utils/PrivateRoutes";
import AdminRoutes from "./utils/AdminRoutes";
import UserRoutes from "./utils/UserRoutes";
import SuperAdminRoutes from "./utils/SuperAdminRoutes";

import Home from "./pages/Home/Home";
import AdminManageRolesPage from "./pages/AdminManageRolesPage/AdminManageRolesPage";
import SubmitSurvey from "./pages/UserDashboard/SubmitSurvey/SubmitSurvey";
import ApproveSurvey from "./pages/UserDashboard/ApproveSurvey/ApproveSurvey";
import VerifySurveyPage from "./pages/VerifySurveyPage/VerifySurveyPage";
import SubmitSurveyPage from "./pages/SubmitSurveyPage/SubmitSurveyPage";
import ApproveSurveyPage from "./pages/ApproveSurveyPage/ApproveSurveyPage";
import PDFCodeNew from "./components/SubmitSurveyList/PDFCodeNew";
import ViewApprovedSurveys from "./pages/ViewApprovedSurveys/ViewApprovedSurveys";
import ViewApprovedSurveyPage from "./pages/ViewApprovedSurveyPage/ViewApprovedSurveyPage";
import AllUsers from "./pages/AllUsers/AllUsers";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import UserDashboard from "./pages/UserDashboard/UserDashboard";
import { getEthBalance } from "./redux/actions/eWarrant";
import SuperAdminDashboard from "./pages/SuperAdminDashboard/SuperAdminDashboard";
import SubmitSurveys from "./pages/SubmitSurveys/SubmitSurveys";
import ApproveSurveys from "./pages/ApproveSurveys/ApproveSurveys";
import ViewSurveys from "./pages/ViewSurveys/ViewSurveys";
import AdminInfoPage from "./pages/AdminInfoPage/AdminInfoPage";
import AdminSurveysForSuperAdmin from "./pages/AdminSurveysForSuperAdmin/AdminSurveysForSuperAdmin";
import AdminEachSurveyForSuperAdmin from "./pages/AdminEachSurveyForSuperAdmin/AdminEachSurveyForSuperAdmin";
import ManageAccess from "./pages/ManageAccess/ManageAccess";
import ExternalUserViewSurveys from "./pages/ExternalUserViewSurveys/ExternalUserViewSurveys";
import ExternalUserViewSurveysByAdmin from "./pages/ExternalUserViewSurveysByAdmin/ExternalUserViewSurveysByAdmin";
import ExternalUserViewASurveyByAdmin from "./pages/ExternalUserViewASurveyByAdmin/ExternalUserViewASurveyByAdmin";
import AdminAssignedSurveys from "./pages/AdminAssignedSurveys/AdminAssignedSurveys";
import AssignedWorkflowPage from "./pages/AssignedWorkflowPage/AssignedWorkflowPage";
import CIODashboardPageNew from "./pages/CIODashboardPageNew/CIODashboardPageNew";
import CIOModelsInUsePage from "./pages/CIOModelsInUsePage/CIOModelsInUsePage";
import CIOBackpacksPage from "./pages/CIOBackpacksPage/CIOBackpacksPage";
import CIOWorkflowsPage from "./pages/CIOWorkflowsPage/CIOWorkflowsPage";
import CIODatasetsPage from "./pages/CIODatasetsPage/CIODatasetsPage";
import CIOModelPage from "./pages/CIOModelPage/CIOModelPage";
import CIOBackpackPage from "./pages/CIOBackpackPage/CIOBackpackPage";
import CIOModelBackpackPage from "./pages/CIOModelBackpackPage/CIOModelBackpackPage";
import CIODeployedModelBackpackPage from "./pages/CIODeployedModelBackpackPage/CIODeployedModelBackpackPage";
import CIOBackpacksModelNFTPage from "./pages/CIOBackpacksModelNFTPage/CIOBackpacksModelNFTPage";
import Test from "./pages/Test/Test";
import CIOModelsByModelCurator from "./pages/CIOModelsByModelCurator/CIOModelsByModelCurator";

const App = () => {
  const sessionId = JSON.parse(
    localStorage.getItem("openlogin_store")
  )?.sessionId;
  useEffect(() => {
    sessionId === "" && localStorage.clear() && window.location.reload();
  }, [sessionId]);

  const dispatch = useDispatch();
  useEffect(() => {
    sessionId && sessionId !== "" && dispatch(getEthBalance());
  }, [dispatch, sessionId]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<CIODashboardPageNew />} />
          <Route
            path="/backpack/:backpackId"
            element={<CIOModelBackpackPage />}
          />
          <Route
            path="/backpack/:backpackId/:modelId"
            element={<CIOBackpacksModelNFTPage />}
          />
          <Route
            path="/modelCurator"
            element={<CIOModelsByModelCurator />}
          />
          <Route
            path="/test"
            element={<Test/>}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
